import { camelCase, startCase } from 'lodash';

import { clearLocale, setLocale } from 'src/translations/i18n';

import { GuestInfo, GuestType, SwitchGuestInfo } from './user.types';

const STORAGE_KEY = {
  AUTH_TOKEN: 'auth_token',
  GUEST_INFO: 'guest_info',
  SWITCH_GUEST_INFO: 'switch_guest_info',
} as const;

export const GUEST_TYPE = {
  INSTRUCTOR: 'instructor',
  TRAINEE: 'trainee',
  FUNDING_AGENCY: 'funding_agency',
  COMPANY: 'company',
  USER: 'user',
  ACADEMY: 'academy',
} as const;

/**
 * Check whether the given guest's type is one of the given types.
 * @param guestInfo Guest info to check.
 * @param types The types to check against.
 * @returns `true` if the given guest's type is one of the given types, `false` otherwise.
 */
export function isGuestOfTypes(guestInfo: GuestInfo | undefined, types: GuestType[]): boolean {
  if (!guestInfo) {
    return false;
  }

  return (types as unknown[]).includes(guestInfo.type);
}

/**
 * Normalize a GraphQL typename to a guest type.
 * @param guestType GraphQL's typename of the guest type to convert.
 * @returns The normalized version of a GraphQL typename for the guest type.
 */
export function guestTypeFromTypename(guestType: string) {
  return startCase(camelCase(guestType)).replace(/ /g, '');
}

/**
 * Get the current academy code.
 * @returns The current academy code.
 */
export function getUserCode() {
  const hostParts = window.location.hostname.split('.');
  return hostParts[0];
}

/**
 * Get the currently logged-in user token.
 * @returns The logged in user token, or `undefined` if no user is logged in.
 */
export function getToken() {
  const token = localStorage.getItem(`${getUserCode()}:${STORAGE_KEY.AUTH_TOKEN}`);

  if (!token) {
    return undefined;
  }

  return token;
}

/**
 * Get whether a user is currently logged in.
 * @returns `true` if a user is logged in, `false` otherwise.
 */
export function isLoggedIn() {
  return getToken() !== undefined;
}

/**
 * Get the currently logged in user's info.
 * @returns The user info, or `undefined` if not logged in.
 */
export function getGuestInfo() {
  const serializedInfo = localStorage.getItem(`${getUserCode()}:${STORAGE_KEY.GUEST_INFO}`);

  if (!serializedInfo) {
    return undefined;
  }

  return JSON.parse(serializedInfo) as GuestInfo;
}

/**
 * Get the logged in user ID.
 * @returns The user ID, or `undefined` if not logged in.
 */
export function getGuestId() {
  return getGuestInfo()?.id;
}

/**
 * Get the logged in user type.
 * @returns The user type, or `undefined` if not logged in.
 */
export function getGuestType() {
  return getGuestInfo()?.type.toLocaleLowerCase();
}

/**
 * Get the logged in user name.
 * @returns The user name, or `undefined` if not logged in.
 */
export function getGuestName() {
  return getGuestInfo()?.name;
}

/**
 * Get the switch user info.
 * @returns The switch user info.
 */
export function getSwitchGuestInfos() {
  const serializedSwitchGuestInfo = localStorage.getItem(`${getUserCode()}:${STORAGE_KEY.SWITCH_GUEST_INFO}`);

  if (!serializedSwitchGuestInfo) {
    return [];
  }

  return JSON.parse(serializedSwitchGuestInfo) as SwitchGuestInfo[];
}

/**
 * Get whether the user needs to accept the terms of service.
 * @returns A boolean, or `undefined` if not logged in.
 */
export function shouldAcceptTOS() {
  return getGuestInfo()?.shouldAcceptTos;
}

/**
 * Log a user in.
 * @param guest Guest to log in.
 * @param token Token of the guest.
 * @param switchGuestInfo Switch guest info of the guest.
 */
export function setLoggedIn(guest: GuestInfo, token: string, switchGuestInfo: SwitchGuestInfo) {
  const serializedGuest = serializeGuest(guest);
  const serializedSwitchInfo = serializeSwitchInfo(switchGuestInfo);

  localStorage.setItem(`${getUserCode()}:${STORAGE_KEY.AUTH_TOKEN}`, token);
  localStorage.setItem(`${getUserCode()}:${STORAGE_KEY.GUEST_INFO}`, serializedGuest);
  localStorage.setItem(`${getUserCode()}:${STORAGE_KEY.SWITCH_GUEST_INFO}`, serializedSwitchInfo);
  setLocale(guest.locale);
}

/**
 * Sign the user out.
 */
export function signout() {
  localStorage.removeItem(`${getUserCode()}:${STORAGE_KEY.AUTH_TOKEN}`);
  localStorage.removeItem(`${getUserCode()}:${STORAGE_KEY.GUEST_INFO}`);
  localStorage.removeItem(`${getUserCode()}:${STORAGE_KEY.SWITCH_GUEST_INFO}`);
  clearLocale();
}

/**
 * Save that the user has accepted the terms of service.
 */
export function acceptTOS() {
  const guest = getGuestInfo();

  if (!guest) {
    return;
  }

  guest.shouldAcceptTos = false;

  const serializedGuest = serializeGuest(guest);

  localStorage.setItem(`${getUserCode()}:${STORAGE_KEY.GUEST_INFO}`, serializedGuest);
}

/**
 * Save a new picture URL for the user.
 * @param url URL of the new picture.
 */
export function setPictureUrl(url: string) {
  const guest = getGuestInfo();

  if (!guest) {
    return;
  }

  guest.logo = {
    url: url,
  };

  const serializedGuest = serializeGuest(guest);

  localStorage.setItem(`${getUserCode()}:${STORAGE_KEY.GUEST_INFO}`, serializedGuest);
}

function serializeGuest(guest: GuestInfo) {
  const sanitizedGuest = {
    id: guest.id,
    locale: guest.locale,
    logo: guest.logo,
    name: guest.name,
    shouldAcceptTos: guest.shouldAcceptTos,
    type: guest.type.toLocaleLowerCase(),
  };

  return JSON.stringify(sanitizedGuest);
}

function serializeSwitchInfo(switchInfo: SwitchGuestInfo) {
  return JSON.stringify(switchInfo);
}
